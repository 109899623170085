import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import BGVideo from '../layout/BGVideo';

class ScrollItem__BillboardProject extends VScroll_Item {
  timeline;
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onMove = () => {
      //BGVideo.blur = (this.progressTop - 1) * 50;
      //BGVideo.scale = Math.max(1, this.progressTop);
    }
  }
}

Scroll._registerClass('billboard-project', ScrollItem__BillboardProject);