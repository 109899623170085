import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Functions } from '../_app/cuchillo/utils/Functions';
import BGVideo from '../layout/BGVideo';
import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';

export default class Talents extends Default {
  _projects;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor() {
    super();

    this.setupItems();
  }

  setupItems() {
    this._projects = GetBy.class('project-card', this.item);

    let positions = isMobile ? Functions.arrayRandom(['--pos-1', '--pos-2', '--pos-3']) : Functions.arrayRandom(['--pos-1', '--pos-2', '--pos-3', '--pos-4', '--pos-5']);
    const speeds = Functions.arrayRandom(['.2', '.1', '0']);

    let cont = 0;
    let last = '';

    for (let i = 0, j = this._projects.length; i < j; i++, cont++) {
      const item = this._projects[i];

      if (cont === positions.length) {
        cont = 0;
        positions = Functions.arrayRandom(positions);
      }

      if (last === positions[cont]) cont++;

      item.classList.add(positions[cont]);
      last = positions[cont];

      /*if (!isMobile) {
        item.setAttribute('data-scroll-insider', 'true');
        item.setAttribute('data-speed', Functions.arrayRandom(speeds)[0]);
      }*/
    }
  }


  beforeShow() {
    document.body.classList.add("--no-fixed");
    super.beforeShow();
    LogoGlitch.goTop();
    LogoGlitch.offsetY = isSmartphone? Metrics.HEIGHT * .5 : Metrics.HEIGHT * 1;
    LogoGlitch.modY = isSmartphone? .6 : .6;
    LogoGlitch.showLettering();
    LogoGlitch.start();
    BGVideo.goBlur();
  }

  hide__effect() {
    BGVideo.goNormal();
    super.hide__effect();
  }

  afterHide() {
    
    document.body.classList.remove("--no-fixed");
    LogoGlitch.offsetY = 0;
    LogoGlitch.modY = 1;
    LogoGlitch.stop();
    super.afterHide();
  }
}

ControllerPage._addPage('page-artist', Talents)
