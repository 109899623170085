import gsap, { Power2, Power3 } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from '../_app/cuchillo/core/Basics';

export default class BGVideo {
  static container;
  static _front;
  static _preloader;
  static _callback;
  static isFirstTime = true;
  static isReady = false;
  static isShow = false;
  static isBlocked = false;
  static _idTimerBlock = 0;
  static _waitSrc = null;
  static _actualSrc = null;

  static _blur = 0;
  static get blur() { return this._blur; }
  static set blur(__n) {
    this._blur = __n;
    this.container.style.setProperty('--blur', `${this._blur}px`);
  }

  static _scale = 0;
  static get scale() { return this._scale; }
  static set scale(__n) {
    this._scale = __n;
    this.container.style.setProperty('--scale', `${this._scale}`);
  }

  static init() {
    this.container = GetBy.id("BGVideo");
    this._front = GetBy.selector("video", this.container)[0];
    this._preloader = GetBy.selector("video", this.container)[1];
  }

  static start(__call) {
    this._callback = __call;

    if(!this.isFirstTime) {
      const video = GetBy.selector("[data-video-init]")[0]?.dataset.videoInit;
      if (video) {
        this.play(video);
      } else {
        this.hide();
      }
    } else {
      this.isFirstTime = false;

      if(this._front.currentTime == 0) {
        this._front.addEventListener('play', () => {
          if(this._callback) this._callback();
          this._callback = null;
        }, { once: true });
      } else {
        if(this._callback) {
          this._callback();
          this._callback = null;
        }
      }
    }
  }

  static semihide() {
    this.isShow = false;
    gsap.to(this.container, { alpha: .2, duration: 2, ease: Power2.easeOut });
  }

  static play(__src) {
    if(this._actualSrc === __src) return false;

    if (this.isBlocked) {
      this._waitSrc = __src;
      return;
    }

    this.isBlocked = true;
    this._idTimerBlock = setTimeout(() => {
      this.isBlocked = false;
      if (this._waitSrc) {
        this.play(this._waitSrc);
        this._waitSrc = null;
      }
    }, 50);

    clearTimeout(this._timerOut);

    this.container.classList.add("--show");

    this._src = __src;
    this._actualSrc = __src;

    if (this.isReady) { this._preloader.pause(); }

    this.isReady = false;

    this._preloader.setAttribute("src", this._src);
    const playPromise = this._preloader.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        this.isReady = true;
        this.show();
        this.changeVideo();
        
        if(this._callback ) this._callback();
        this._callback = null;
      })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
        });
    }
  }

  static changeVideo() {
    const temp = this._front;
    this._front = this._preloader;
    this._preloader = temp;

    this._front.classList.remove("video-preloader");
    this._preloader.classList.add("video-preloader");

    this._preloader.setAttribute("src", "");
  }

  static goBlur() {
    gsap.to(this, { blur: 20, scale:1.5, duration: .8, ease: Power3.easeOut });
  }

  static goNormal() {
    gsap.to(this, { blur: 0, scale:1, duration: .4, ease: Power2.easeOut });
  }

  static show() {
    if (this.isShow) return;

    this.isShow = true;
    gsap.killTweensOf(this.container);
    gsap.to(this.container, { alpha: 1, duration: .2, ease: Power2.easeOut });
  }

  static hide() {
    clearTimeout(this._idTimerBlock);
    this.isBlocked = false;

    if(Basics.tempValue === "cancel_hide") {
      Basics.tempValue = "";
      return;
    }

    this._timerOut = setTimeout(() => {
      clearTimeout(this._timerOut);

      this.isShow = false;
      gsap.to(this.container, {
        alpha: 0,
        duration: .2,
        delay: 0,
        ease: Power2.easeOut,
        onComplete: () => {
          if (this.isReady) {
            this._front.pause();
            this._preloader.pause();
          }

          this._front.setAttribute("src", "");
          this._preloader.setAttribute("src", "");
          this._actualSrc = null;
        }
      });
    }, 50);
  }

  static dispose() {
    this.hide();
  }
}


