import Keyboard from '../_app/cuchillo/core/Keyboard';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Header from '../layout/Header';
import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';

export default class Project extends Default {
   
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
  }

  beforeShow() {
    ControllerPage.enable_ESC_Mode();
    Header.hideAlpha();
    Keyboard.mountPage(this.id);
  }

  afterShow() {
    this._isActive = true;
  }

  beforeHide() {
    ControllerPage.enable_ESC_Mode(false);
    super.beforeHide();
  }

  afterHide() {
    super.afterHide();
    Header.showAlpha();
  }
}

ControllerPage._addPage("page-project", Project)
