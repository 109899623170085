import gsap, { Power2 } from 'gsap';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { Basics, hasSlowConnection, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import EventDispatcher from '../_app/cuchillo/core/EventDispatcher';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import ParticlesProjectList from '../components/ParticlesProjectList';
import BGVideo from '../layout/BGVideo';
import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class Home extends Default {
   
  static videos = []
  static idTimer;
  static isVideoLoaded = true;
  visor;
  particlesHome;
  projectsHome = [];
  actual = 0;
  _calls = {
    click: (e) => { this._click(e); },
  }
  
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();

    this.visor = new Home__Visor(GetBy.selector("[data-visor]")[0]);
    this.particlesHome = new ParticlesProjectList(this.container);
    this.setupButtons();
    LogoGlitch.gotoMid();
  }

  videoLoaded() {
    Home.isVideoLoaded = true;
  }

  static addVideo(video) {
    Home.videos.push(video)
  }

  static playVideos(index = 0) {
    if(hasSlowConnection) return;

    if(Home.videos.length === 0) {
      Home.idTimer = setTimeout(()=> { Home.playVideos(0) }, 100);
      return;
    }

    let i = index;

    for(i; i<Math.min(index + 5, Home.videos.length); i++) {
      Home.videos[i].play()
    }

    if(i < Home.videos.length) {
      Home.idTimer = setTimeout(()=> { Home.playVideos(i) }, 500);
    }
  }

  setupButtons() {
    this.container.addEventListener(isTouch? Basics.clickEvent : Basics.mouseOver, this._calls.click);

    if(!this.particlesHome) return;
    this.particlesHome.scroller._items.map(item => {
      this.projectsHome.push(item.data);
    });
  }

  _click(e) {
    switch (e.target.tagName.toLowerCase()) {
      case "a":
        if (e.target.getAttribute("data-index") !== null) {
          e.preventDefault();
          e.stopPropagation();
          this.selectProject(Number(e.target.dataset.index));        
        }
      
        break;
    }
  }

  dispose() {
    this.container.addEventListener(isTouch? Basics.clickEvent : Basics.mouseOver, this._calls.click);
    super.dispose();
  }

  selectProject(__index) {
    this.actual = __index;
    this.visor.change(this.projectsHome[__index]);
  }

  beforeShow() {
    if(this.particlesHome) this.particlesHome.show();
    Keyboard.mountPage(this.id);
  }

  afterShow() {
    this._isActive = true;
   
  }

  beforeHide() {
    Home.videos = [];
    clearTimeout(Home.idTimer);
    
    if(this.particlesHome) this.particlesHome.hide();
    this.visor.hide();
    super.beforeHide();
  }

  afterHide() {
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    if(this.particlesHome) this.particlesHome.resize();
  }
   //LOOP
  loop() {
    
    //if (this._isActive) {
        //super.loop();
        /*if(!isSmartphone)*/ this.particlesHome.loop();
   // }
  }
}

class Home__Visor {
  isShow = false;
  container = GetBy.selector("[data-visor]")[0];
  domFooter = GetBy.class("widget-home__footer")[0];
  items = {};
  dom = {};  

  constructor(__dom) {
    this.container = __dom;
    this.init();
  }

  init() {
    this.dom = {
      id: GetBy.selector("[data-project-id]", this.container)[0],
      title: GetBy.selector("[data-project-title]", this.container)[0],
      director: GetBy.selector("[data-project-director]", this.container)[0],
      description: GetBy.selector("[data-project-description]", this.container)[0],
      next: GetBy.selector("[data-project-next]", this.container)[0],
      prev: GetBy.selector("[data-project-prev]", this.container)[0],
      see: GetBy.selector("[data-project-link]", this.container)[0],
    }

    this.items = {
      id: new Shffl(this.dom.id, {textFrom: ""}),
      title: new Shffl(this.dom.title, {textFrom: ""}),
      director: new Shffl(this.dom.director, {textFrom: ""}),
      description: new Shffl(this.dom.description, {textFrom: ""}),
      next: new Shffl(this.dom.next, {textFrom: "", textTo:GetBy.selector("[data-project-next]", this.container)[0].getAttribute("data-text-from")}),
      prev: new Shffl(this.dom.prev, {textFrom: "", textTo:GetBy.selector("[data-project-prev]", this.container)[0].getAttribute("data-text-from")}),
      see: new Shffl(this.dom.see, {textFrom: ""}),
    }
  }

  change(data) {
    /*this.items.id.dispose();
    this.items.title.dispose();
    this.items.director.dispose();
    this.items.description.dispose();*/

    if(!this.isShow) {
      this.isShow = true
      /*this.items.next.play();
      this.items.prev.play();*/
      //this.items.see.play();

      if(isSmartphone) {
        gsap.to(this.domFooter,{alpha:0,duration:.2,ease:Power2.easeIn})
      }
    }

    if(this.items.id) {
      this.items.id.end();
      this.items.title.end();
      this.items.director.end();
      this.items.description.end();
    }

    //if(BGVideo.play(data.video)) BGVideo.semihide();
    this.dom.see.href = data.href;

    this.items.id = new Shffl(this.dom.id, {textTo: data.id});
    this.items.title = new Shffl(this.dom.title, {textTo: data.title});
    this.items.director = new Shffl(this.dom.director, {textTo: data.director});
    this.items.description = new Shffl(this.dom.description, {textTo: data.description});
    this.items.see = new Shffl(this.dom.see, {textTo: "{see project}"});

    this.items.id.play();
    this.items.title.play();
    this.items.director.play();
    this.items.description.play();
    this.items.see.play();
  }

  hide() {
    this.items.id.reverse();
    this.items.title.reverse();
    this.items.director.reverse();
    this.items.description.reverse();
    this.items.next.reverse();
    this.items.prev.reverse();
    this.items.see.reverse();
    this.isShow = false;
  }
}

ControllerPage._addPage("page-home", Home)
