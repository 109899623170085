import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

import { Shffl } from '../_app/cuchillo/components/Shffl';
import { GetBy } from '../_app/cuchillo/core/Element';

export default class ShfflController {
  container;
  items = [];
  timers = [];

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__container = document.body) {
    this.setupItems(__container);
  }

  setupItems(__container) {
   
    this.setupLines(__container);
    this.setupLines(__container, '[data-shffl-holder-wysiwyg] p');

    let containers = [...GetBy.selector("[data-shffl-holder]", __container)];
    if (containers.length === 0) containers.push(__container);

    containers.map(container => {
      let temp = [];
      [...GetBy.selector("[data-shffl]", container)].map(dom => {
        temp.push(new Shffl(dom, {
          duration: dom.getAttribute('data-duration') || this._getTime(dom.textContent),
          textFrom: "",
          textTo: dom.textContent
        }));
      });
      this.items.push(temp);
    })
  }

  setupLines(__container, __selector = "[data-shffl-holder-lines]") {
    let containers = [];
    const containersLines = [...GetBy.selector(__selector, __container)];
   
    containersLines.map(container => {
      
      container.setAttribute('data-shffl-holder', '');

      const lines = new SplitText(container, { type: 'lines' }).lines;
      lines.map(line => {
        const text = line.innerHTML;
        const fake = document.createElement('span');
        fake.innerHTML = text;
        const anim = fake.cloneNode(true);

        fake.classList.add('fake');
        anim.classList.add('anim');
        anim.setAttribute('data-shffl', '');
        anim.setAttribute('data-origin', 'right');

        line.innerHTML = '';
        line.appendChild(fake);
        line.appendChild(anim);
      });
      containers.push(container);
    });

    return containers;
  }

  show() {
    for (let i = 0; i < this.items.length; i++) {
      for (let j = 0; j < this.items[i].length; j++) {
        this.delayCall(() => { this.items[i][j].play() }, 100 * j)
      }
    }
  }

  hide() {
    this.disposeTimers();

    for (let i = 0; i < this.items.length; i++) {
      for (let j = 0; j < this.items[i].length; j++) {
        this.items[i][j].reverse();
      }
    }
  }

  delayCall(__call, __delay) {
    const idTimer = setTimeout(__call, __delay);
    this.timers.push(idTimer);
  }

  disposeTimers() {
    this.timers.map(id => clearTimeout(id));
    this.timers = [];
  }

  dispose() {
    this.disposeTimers();

    for (let i = 0; i < this.items.length; i++) {
      for (let j = 0; j < this.items[i].length; j++) {
        this.items[i][j].dispose();
      }
    }
  }

  _getTime(__text) {
    return Math.max(.3, Math.min(.8, (__text.length * .4) / 10));
  }
}

