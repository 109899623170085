import gsap, { Expo, Power2 } from 'gsap';
import { Shffl } from '../_app/cuchillo/components/Shffl';
import { Videos } from '../_app/cuchillo/components/Videos';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import BGVideo from '../layout/BGVideo';
import LogoGlitch from '../layout/LogoGlitch';
// import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';

export default class Archive extends Default {
  projects;
  _shuffles = [];
  _calls = {
    hover: (e) => this.hover(e.target),
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor() {
    super();

    this.projects = GetBy.class('__project', this.container);
    
    if (!isMobile) {
      BGVideo.scale = 1;
      BGVideo.show();

      const itemsOut = GetBy.selector("[data-shffl-link-out]");
      C.forEach(itemsOut, (el) => {
        this._shuffles.push(new ShffleLinks__Out(el));
      });
    }
  }

  beforeShow() {

    for (let i = 0; i < this._shuffles.length; i++) {
      this._shuffles[i].show(.1 * i);
    }

    super.beforeShow();
    LogoGlitch.goBottom();
  }

  afterShow() {
    super.afterShow();
  }

  afterHide() {
    super.afterHide();

    if (isMobile) return;
    for (let i = 0; i < this._shuffles.length; i++) {
      this._shuffles[i].dispose();
    }
  }
}

ControllerPage._addPage('page-archive', Archive);

class ShffleLinks__Out {
  _shuffles = [];
  _shufflesShow = [];
  _dom;
  _video;
  _isPlay = false;
  _src
  _timerShow;
  _bg;
  _timerPlay;
  _figure;
  _calls = {
    in: () => this.in(),
    out: () => this.out(),
  }

  constructor(__dom) {
    this._dom = __dom;
    this._video = GetBy.selector('video', this._dom)[0];
    this._bg = GetBy.class('bg-image', this._dom)[0];
    this._figure = GetBy.selector('figure', this._dom)[0];

    this._src = this._video.getAttribute("src");
    this._video.setAttribute("src", "");

    this.init();
  }

  init() {
    
    this._dom.addEventListener(Basics.mouseOver, this._calls.in, { passive: true });
    this._dom.addEventListener(Basics.mouseOut, this._calls.out, { passive: true });

    const targets = GetBy.selector('[data-shffl-target]', this._dom);
    for (let i = 0; i < targets.length; i++) {
      const shuffle = new Shffl(targets[i],
        {
          duration: .6,
          textTo: targets[i].getAttribute('data-text-to') || targets[i].innerText,
          origin: Shffl.LEFT
        });

      const shuffleShow = new Shffl(targets[i],
        {
          duration: .6,
          textFrom: "",
          textTo: targets[i].getAttribute('data-text-to') || targets[i].innerText,
          origin: Shffl.LEFT
        });


      this._shuffles.push(shuffle);
      this._shufflesShow.push(shuffleShow);
    }
  }

  show(__delay) {
    this._timerShow = setTimeout(()=> {
      gsap.to(this._bg,{alpha:.3, duration:.3, ease:Power2.easeOut, delay:.4, force3D:true});
      //gsap.to(this._figure,{x:0, duration:.8, ease:Expo.easeOut, delay:.3, force3D:true});

      for (let i = 0; i < this._shufflesShow.length; i++) {
        this._shufflesShow[i].play();
      }
    }, __delay*1000);
  }

  in() {
    if (this._video) {
      //this._timerPlay = setTimeout(()=> {
        this._video.setAttribute("src", this._src);
        this._video.play();
        this._isPlay = true;
      //}, 300)
    }
    gsap.killTweensOf(this._bg);
    gsap.to(this._bg,{alpha:1, duration:.1, ease:Power2.easeOut, delay:0, force3D:true});
  }

  out() {
    if (this._video) {
      if(this._isPlay)  this._video.pause();
      clearTimeout(this._timerPlay);
      this._video.setAttribute("src", "");
    }
    gsap.to(this._bg,{alpha:.3, duration:2, ease:Power2.easeOut, delay:0, force3D:true});
    //gsap.to(this._bg,{width:"0%", duration:.8, ease:Expo.easeOut, force3D:true});

    for (let i = 0; i < this._shuffles.length; i++) {
      this._shuffles[i].play();
    }
  }

  dispose() {
    clearTimeout(this._timerOut);
    clearTimeout(this._timerShow);

    if (this._video) {
      if(this._isPlay)  this._video.pause();
      clearTimeout(this._timerPlay);
      this._video.setAttribute("src", "");
    }

    for (let i = 0; i < this._shuffles.length; i++) {
      this._shuffles[i].dispose();
      this._shufflesShow[i].dispose();
    }

    this._dom.removeEventListener(Basics.mouseOver, this._calls.in);
    this._dom.removeEventListener(Basics.mouseOut, this._calls.out);

    this._targets = null;
    this._shuffles = null;
    this._shufflesShow = null;
    this._doms = null;
    this._video = null;
  }
}

