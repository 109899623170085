import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class LogoHeaderGlitch {
  static color = "#E5FF70"
  static tick = 0;
  static images = [];
  static current = 0;
  static hasEffect = true;
  static isEnabled = false;

  static options = {  alpha: 1  }

  static canvas = { front: GetBy.id("CanvasHeader") }
  static ctx = { front: this.canvas.front.getContext('2d') }

  static width;
  static height;
  static image;
  static paths;

  static init(__paths) {
    this.setupPaths(__paths);
    this.start();
  }

  static setupPaths(__paths) {
    this.paths = __paths;
    for (let i = 0; i < this.paths.length; i++) {
      this.images.push(new LogoHeaderGlitch__Image(this.paths[i], this.ctx.front))
    }
  }

  static showLettering() {
    this.current =  Math.max(0, this.images.length - 1);
  }

  static showSmile() {
    this.current =  Math.max(0, Maths.maxminRandom(this.images.length - 2, 0));
  }

  static start() {
    clearTimeout(this.idTimer);
    this.enableEffect();
    this.isEnabled = true;
  }

  static startBasic() {
    //clearTimeout(this.idTimer);
    //this.idTimer = setTimeout(() => { this.enableEffect(); }, Maths.maxminRandom(8000, 2000) * 10);
    //this.images[this.current].offset = 400;
    this.isEnabled = true;
  }

  static stopBasic() {
    clearTimeout(this.idTimer);
    this.isEnabled = false;
    this.ctx.front.clearRect(0, 0, this.width, this.height);
  }

  static stop() {
    clearTimeout(this.idTimer);
    this.images[this.current].offset = Metrics.HEIGHT * .2;

    this.idTimer = setTimeout(() => {
      this.isEnabled = false;
      this.ctx.front.clearRect(0, 0, this.width, this.height);
    }, 500);
  }

  static gotoMid() {
    this.options.alpha = .95;
  }

  static goBottom() {
    this.options.alpha = .99;
  }

  static goTop() {
    this.options.alpha = .95;
  }

  static enableEffect() {
    this.hasEffect = true;

    setTimeout(() => {
      this.hasEffect = false;
    }, Maths.maxminRandom(30, 10) * 100);

    this.idTimer = setTimeout(() => { this.enableEffect(); }, Maths.maxminRandom(80, 40) * 200);
  }

  static loop() {
    if (!this.isEnabled) return;

    this.tick++;
    if (this.tick % 10 == 0) {
      this.ctx.front.clearRect(0, 0, this.width, this.height);
    }

    if (this.tick % 8 == 0 && this.hasEffect) {
       this.current = Maths.maxminRandom(this.images.length - 2,0);
    }

    this.images[this.current].draw(this.hasEffect, this.color);
  }

  static resize() {
    this.width = this.canvas.front.offsetWidth * Sizes.RATIO;
    this.height = this.canvas.front.offsetHeight * Sizes.RATIO;
    
    this.canvas.front.setAttribute('width', this.width);
    this.canvas.front.setAttribute('height', this.height);
    
    for (let i = 0; i < this.paths.length; i++) {
      this.images[i].resize();
    }

    this.loop();
  }
}

class LogoHeaderGlitch__Image {
  image = new Image();
  enabled = false;
  alpha = 1;
  path2D;
  _scale;
  _width;
  _height;
  _heightSVG;
  _x = 1;
  _y = 1;

  tick = 0;
  tickStop = 0;

  offset = 4 * 2;
  maxOffset;
  minOffset;
  tickLimit = 30;

  sizeMod = .5;
  

  constructor(__path, __ctx, __w = 80, __h = 70) {
    this._ctx = __ctx;
    this._ratio = __w / __h;
    
    this._heightSVG = __h;
    this._scale = (LogoHeaderGlitch.height * this.sizeMod)/this._heightSVG;
    this._height = this._heightSVG * this._scale;
    this._width = this._height * this._ratio;

    this.maxOffset = (this._heightSVG/200) * this._scale;
    this.minOffset = (this._heightSVG/1000) * this._scale;

    this._x = ((LogoHeaderGlitch.width *.5)) - this._width * .5;
    this._y = ((LogoHeaderGlitch.height *.5)) - this._height * .5;

    this.path2D = new Path2D(__path);
  }

  draw(__hasEffect = false, color) {
    this.tick++
    

      let modX = 0;
      let modY = 0;
      let modXp = 0;
      let modYp = 0;
      let rotationAngle = 0;

     

      if (this.tick % this.tickLimit == 0) {
        this.offset = Maths.maxminRandom(this.maxOffset, this.minOffset*2) * 2;
        this.tickLimit = Maths.maxminRandom(200, 40);
      }

      this.offset = Math.max(this.minOffset, this.offset * .9);

     if (this.tick % 3 == 0) {
        modX = (Maths.maxminRandom(this.offset, -this.offset) * Sizes.RATIO) * 2;
        modY = (Maths.maxminRandom(this.offset, -this.offset) * Sizes.RATIO) * 2;
        modXp = Maths.maxminRandom(this.offset, -this.offset)/this._heightSVG;
        modYp = Maths.maxminRandom(this.offset, -this.offset)/this._heightSVG;

       
        
      }
      // Guardar el estado actual del contexto
      this._ctx.save();
      // Aplicar rotación aleatoria
      // this._ctx.translate(this._x + this._width / 2, this._y + this._height / 2); // Establecer el punto de rotación en el centro de la imagen
      this._ctx.globalAlpha = this.alpha;
      this._ctx.rotate(rotationAngle);        
      this._ctx.translate(this._x + modX * .5, this._y + modY * .5);
      this._ctx.scale(this._scale + modXp, this._scale + modYp);
      this._ctx.fillStyle = color;
      this._ctx.fill(this.path2D);
      this._ctx.restore();


      /*this._ctx.globalAlpha = this.alpha;
      this._ctx.drawImage(this.image, this._x + modXp * .5, this._y + modYp * .5, this._width + modX, this._height + modY);
      this._ctx.restore();*/
      //this._ctx.translate(0,0);
  
  }

  resize() {
   
      
     
      
      this._scale = (LogoHeaderGlitch.height * this.sizeMod)/this._heightSVG;
      this._height = this._heightSVG * this._scale;
      this._width = this._height * this._ratio;

      this.maxOffset = (this._heightSVG/200) * this._scale * 2;
      this.minOffset = (this._heightSVG/1000) * this._scale * 2;      
      
      this._x = ((LogoHeaderGlitch.width *.5)) - this._width * .5;
      this._y = ((LogoHeaderGlitch.height *.5)) - this._height * .5;
  
  }
}

