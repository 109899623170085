import gsap, { Power3, Power4 } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import ShfflController from '../components/ShfflController';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__Footer extends VScroll_Item {
  _shfflController
  _isFooterShow = false;
  _logo;
  _icon;
  _wrapper;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._logo = GetBy.class('__logo', this.item);
    this._icon = GetBy.class('__icon', this.item);

    this._wrapper = GetBy.class('footer__holder', this.item)[0];
    this._shfflController = new ShfflController(this.item);

    this.hideFooter();

    if (!isSmartphone) {
      this.onMove = () => {
        if (this.progressInside > -.2) {
          if (!this._isFooterShow) {
            this.showFooter();
          }
          this._isFooterShow = true;
        } else {
          if (this._isFooterShow) {
            this.hideFooter();
          }
          this._isFooterShow = false;
        }
      };
    } else {
      
      this.onShow = () => {
        console.log("kjkjk")
        if (!this._isFooterShow) {
          this.showFooter();
        }
        this._isFooterShow = true;
      }

      this.onHide = () => {
        if (this._isFooterShow) {
          this.hideFooter();
        }
        this._isFooterShow = false;
      }
    }
  }

  showFooter() {
    this._wrapper.classList.remove("--disabled")
    
    this._shfflController.show();
    gsap.set(this.item, { alpha: 1 });

    gsap.set(this._logo, { scaleX: 5.5, scaleY: 3, rotate: Maths.maxminRandom(5, -5) });
    gsap.set(this._icon, { scaleX: 5.5, scaleY: 3, rotate: Maths.maxminRandom(5, -5) });

    gsap.to(this._logo, { scaleX: 5.7, scaleY: 3.2, duration: .02 });
    gsap.to(this._icon, { scaleX: 5.7, scaleY: 3.2, duration: .02 });

    gsap.to(this._logo, { rotate: 0, duration: .2, ease: Power3.easeIn });
    gsap.to(this._icon, { rotate: 0, duration: .2, ease: Power3.easeIn });

    gsap.to(this._logo, { scaleX: 1, scaleY: 1, duration: .2, rotate: 0, delay: .01, ease: Power4.easeIn });
    gsap.to(this._icon, { scaleX: 1, scaleY: 1, duration: .2, rotate: 0, delay: .01, ease: Power4.easeIn });

    gsap.to(this._logo, { alpha: 1, duration: .1, delay: 0, ease: Power3.easeOut });
  }

  hideFooter() {
    this._shfflController.hide();
    gsap.to(this.item, { alpha: 0, duration: .1, delay: .3, ease: Power3.easeOut });
    gsap.to(this._logo, { alpha: 0, duration: .2, delay: .2, ease: Power3.easeOut, onComplete: () => { this._wrapper.classList.add("--disabled"); } });
  }

  dispose() {
    super.dispose();
    this._shfflController.dispose();
    this._shfflController = null;
  }
}

Scroll._registerClass('footer', ScrollItem__Footer);
