import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import BGVideo from '../layout/BGVideo';

class ScrollItem__About extends VScroll_Item {
  timeline;
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.onMove = () => {
      BGVideo.blur = (this.progressTop - 1) * 50;
      BGVideo.scale = this.progressTop;
    }
  }
}

Scroll._registerClass('about', ScrollItem__About);