import gsap from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import InfiniteScroll__ItemProductsList from '../scroll/InfiniteScroll__ItemProductsList';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import { isMobile, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';

export default class ParticlesProjectList {
    container;
    scroller;
    holder;
    mainholder;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__container) {
        this.container = __container;
        this.holder = GetBy.class('__holder', this.container)[0];
        this.scroller = new InfiniteScroll(isTouch? InfiniteScroll.AXIS_Y : InfiniteScroll.AXIS_X, {
            domResize: this.container,
            appendContainer: this.holder,
            container: this.holder,
            multiplicator: 1,
            minSpeed: .5,
            inverted: false,
            gap: 0,
            itemClass: InfiniteScroll__ItemProductsList,
            hasVirtuaScroll: true
        });

        this._call = () => this.loop();
    }

    show() {
        //gsap.ticker.add(this._call);
        this.scroller.start();
        this.scroller.show();
    }

    hide() {
        this.scroller.hide();
        this.dispose();
    }

    loop() {
        this.scroller.loop();
    }

    dispose() {
        //gsap.ticker.remove(this._call);
        this.scroller.dispose();
    }

    resize() {
        this.scroller.resize();
    }
}