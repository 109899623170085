import { GetBy, C } from '../_app/cuchillo/core/Element';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { Shffl } from '../_app/cuchillo/components/Shffl';

export default class ShffleLinks {
  static _items = [];

  static init() {
    ShffleLinks.dispose();

    const items = GetBy.selector("[data-shffl-link]");
    C.forEach(items, (el) => {
      //if (el.innerText) {
        this._items.push(new ShffleLinks__Link(el));
      //}
    });

    const itemsChildren = GetBy.selector("[data-shffl-link-children]");
    C.forEach(itemsChildren, (el) => {
      if (el.innerText) {
        this._items.push(new ShffleLinks__Children(el));
      }
    });
  }

  static dispose() {
    this._items.map(item => {
      item.dispose();
    });
    this._items = [];
  }
}

class ShffleLinks__Link {
  _dom;
  _shuffle;
  _timerOut = null;

  _calls = {
    hover: () => this.hover(),
    out: () => this.out(),
  }

  constructor(__dom) {
    this._dom = __dom;

    this.init();
  }

  init() {
    const suffix = this._dom.getAttribute('target') ? this._dom.getAttribute('target') == "_blank" ? " ↗" : " ↘" : "";
    this._shuffle = new Shffl(this._dom,
      {
        duration: .8,
        textTo: this._dom.getAttribute('data-text-to') || this._dom.innerText + suffix,
        origin: Shffl.LEFT
      });

    this._dom.addEventListener(Basics.mouseOver, this._calls.hover, { passive: true });
    this._dom.addEventListener(Basics.mouseOut, this._calls.out, { passive: true });
  }

  hover() {
    clearTimeout(this._timerOut)
    this._shuffle.play();
  }

  out() {
    this._timerOut = setTimeout(() => {
      clearTimeout(this._timerOut)
      this._shuffle.reverse();
    }, 200);
  }

  dispose() {
    clearTimeout(this._timerOut);
    this._shuffle.dispose();
    this._dom.removeEventListener(Basics.mouseOver, this._calls.hover);
    this._dom.removeEventListener(Basics.mouseOut, this._calls.out);

    this._shuffle = null;
    this._dom = null;
  }
}

class ShffleLinks__Children extends ShffleLinks__Link {
  _target;

  constructor(__dom) {
    super(__dom);
  }

  init() {
    this._target = GetBy.selector('[data-shffl-target]', this._dom)[0];
    const suffix = this._dom.getAttribute('target') ? this._dom.getAttribute('target') == "_blank" ? " ↗" : " ↘" : "";

    this._shuffle = new Shffl(this._target,
      {
        duration: .6,
        textTo: this._target.getAttribute('data-text-to') || this._target.innerText + suffix,
        origin: Shffl.RIGHT
      });

    this._dom.addEventListener(Basics.mouseOver, this._calls.hover, { passive: true });
    this._dom.addEventListener(Basics.mouseOut, this._calls.out, { passive: true });
  }

  dispose() {
    clearTimeout(this._timerOut);
    this._shuffle.dispose();
    this._dom.removeEventListener(Basics.mouseOver, this._calls.hover);
    this._dom.removeEventListener(Basics.mouseOut, this._calls.out);

    this._shuffle = null;
    this._target = null;
  }
}
