import { isMobile, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'
import Home from '../pages/Home';

export default class InfiniteScroll__ItemProductList extends InfiniteScroll__Item {
    _card;
    _limits;
    data;
    _card;
    _figure;
    _figureOffset = 0;
    _video;
    _src;
    _isPlay;
    _isShow

    _tickResetLimit = Maths.maxminRandom(1000, 650);

    indice = 0;
    static easing = BezierEasing(.01, .65, 0, .95);

    _ctx = InterfaceCanvas.ctx

    textPosition = {
        x: 0,
        y: 0
    }
    textPosition2 = {
        x: 0,
        y: 0
    }
    fields = {
        name: {x:0,y:0},
        title: {x:0,y:0},
        client: {x:0,y:0},
    }
    offset = {
        grid: Metrics.parseSize("10fpx") * Sizes.RATIO,
        grid2: Metrics.parseSize("40fpx") * Sizes.RATIO,
    }
    style = {
        size: isTouch? Metrics.parseSize("12fpx") * Sizes.RATIO : Metrics.parseSize("16fpx") * Sizes.RATIO,
        unit: "px",
        fontFamilyDirector: 'AreaNormal',
        fontFamily: 'Soehne Mono',
        color: "#E5FF70",
        textAlign: "left"
    };

    constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y) {
        super(__d, __i, __axis, true);

        this.indice = this.index;
        this._card = GetBy.selector('[data-project-button]', this.dom)[0];
        this._video = GetBy.selector('video', this._card)[0];
        this._figure = GetBy.selector('[data-scroll-displace]', this.dom)[0];
        this._card.setAttribute("data-index", this.indice);

        this.data = {
            id: GetBy.selector("[data-project-id]", this._card)[0].dataset.projectId,
            title: GetBy.selector("[data-project-title]", this._card)[0].dataset.projectTitle,
            director: GetBy.selector("[data-project-director]", this._card)[0].getAttribute("data-project-director"),
            directorLine1: GetBy.selector("[data-project-director] span", this._card)[0] ? GetBy.selector("[data-project-director] span", this._card)[0].textContent : "",
            directorLine2: GetBy.selector("[data-project-director] span", this._card)[1] ? GetBy.selector("[data-project-director] span", this._card)[1].textContent : "",
            client: GetBy.selector("[data-project-client]", this._card)[0].textContent,
            description: GetBy.selector("[data-project-description]", this._card)[0].textContent,
            video: this._card.dataset.videoUrl,
            href: this._card.href,
        }

        this._src = this._video.getAttribute("src");
        this.setupLimits();
    }

    setupLimits() {
       if(!this._figure) return;
        this._figureOffset =  isTouch? this._figure.offsetHeight - this.height : this._figure.offsetWidth - this.width;
        this.setupLimitsFields();
    }

    setupLimitsFields() {
        if(isTouch) {
            this.fields = {
                name: {
                    x: Maths.maxminRandom(this.width * .5, this.width * .01) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .1) * Sizes.RATIO,
                },
                title: {
                    x: Maths.maxminRandom(this.width * .5, this.width * .01) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .1) * Sizes.RATIO,
                },
                client: {
                    x: Maths.maxminRandom(this.width * .5, this.width * .01) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .1) * Sizes.RATIO,
                }
            }
        } else {
            this.fields = {
                name: {
                    x: Maths.maxminRandom(this.width * .9, this.width * .05) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .4) * Sizes.RATIO,
                },
                title: {
                    x: Maths.maxminRandom(this.width * .9, this.width * .05) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .4) * Sizes.RATIO,
                },
                client: {
                    x: Maths.maxminRandom(this.width * .9, this.width * .05) * Sizes.RATIO,
                    y: Maths.maxminRandom(this.height * .9, this.height * .4) * Sizes.RATIO,
                }
            }
        }
    }

    resize() {
        super.resize();
        this.setupLimits();
    }

    drawHook() {
        if (!this._card) return;
        this.move();
    }

    play() {
        if (this._isPlay || !Home.isVideoLoaded) return;

        this._isPlay = true;
        this._video.setAttribute("src", this._src);
        this._video.play();
    }

    pause() {
        if (!this._isPlay) return;

        this._isPlay = false;
        this._video.setAttribute("src", "");
    }

    move() {
        const p = Maths.lerp(0, this._figureOffset, this.progressItem);
        this._figure.style[CSS.transform] = isTouch? CSS.translate3D(0, p, 0) : CSS.translate3D(p, 0, 0);

        let position = { x: this.realX, y: this.realY, z: 3 }

        if ((this.realX > -this.width*2 && this.realX < (Metrics.WIDTH + this.width) && !isTouch)
            || (this.realY > -this.height*2 && this.realY < (Metrics.HEIGHT + this.height) && isTouch)) {
            this._isShow = true;
            this.play();
            this.drawText(position);
        } else if (this._isPlay || this._isShow == null) {
            this._isShow = false;
            this.pause();
        }
    }

    drawText(position) {
        /*if(!this.textPosition || this.tick % 30 === 0) {
            this.textPosition = position;
        }*/

        const positions = {
            name1: {
                x: this.fields.name.x,
                y: this.fields.name.y
            },
            name2: {
                x: this.fields.name.x,
                y: this.fields.name.y + this.style.size,
            },
            title1: {
                x: this.fields.title.x,
                y: this.fields.title.y,
            },
            title2: {
                x: this.fields.title.x,
                y: this.fields.title.y + this.style.size,
            },
            client1: {
                x: this.fields.client.x,
                y: this.fields.client.y,
            },
            client2: {
                x: this.fields.client.x,
                y: this.fields.client.y + this.style.size,
            }
        }

        this.textPosition = {
            x: (Math.round(position.x / this.offset.grid) * this.offset.grid) * Sizes.RATIO,
            x2: (Math.round(position.x / this.offset.grid2) * this.offset.grid2) * Sizes.RATIO,
            y: (Math.round(position.y / this.offset.grid) * this.offset.grid) * Sizes.RATIO,
        }

        if (this.tick % 6 === 0) {
            this.textPosition2.x = this.textPosition.x;
            this.textPosition2.y = this.textPosition.y;
        }

        if (this.tick % this._tickResetLimit === 0) {
            this.setupLimitsFields();
            this._tickResetLimit = Maths.maxminRandom(1200, 650);
        }

        this._ctx.globalAlpha = .8;

        this._ctx.fillStyle = this.style.color;
        this._ctx.textAlign = this.style.textAlign;
        this._ctx.font = 900 + " " + this.style.size + this.style.unit + " " + this.style.fontFamilyDirector;
        this._ctx.fillText(this.data.directorLine1.toUpperCase(), this.textPosition2.x + positions.name1.x, this.textPosition.y + positions.name1.y);
        this._ctx.fillText(this.data.directorLine2.toUpperCase(), this.textPosition.x + positions.name2.x, this.textPosition2.y + positions.name2.y);

        this._ctx.font = this.style.size + this.style.unit + " " + this.style.fontFamily;
        this._ctx.fillText("»" + this.data.title.toUpperCase(), this.textPosition.x + positions.title1.x, this.textPosition.y + positions.title1.y);
        this._ctx.fillText(this.data.id.toUpperCase(), this.textPosition2.x + positions.title2.x, this.textPosition2.y + positions.title2.y);

        this._ctx.fillText("WORK FOR", this.textPosition2.x + positions.client1.x, this.textPosition2.y + positions.client1.y);
        this._ctx.fillText(this.data.client.toUpperCase(), this.textPosition.x + positions.client2.x, this.textPosition.y + positions.client2.y);
        
    }

    dispose() {
        super.dispose();
        this._video.setAttribute("src", "");
    }
}