import gsap, { Power3, Power4 } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import ShfflController from '../components/ShfflController';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Basics, isMobile } from '../_app/cuchillo/core/Basics';
import BGVideo from '../layout/BGVideo';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import InterfaceCanvas from '../_app/cuchillo/layout/InterfaceCanvas';

class ScrollItem__Talents extends VScroll_Item {
  timeline;
  artists;
  images = [];
  canvas;
  ctx;

  sizes = {
    width: 0,
    height: 0
  };

  calls = {
    hover: (e) => this.hover(e.target),
    out: (e) => this.out(e.target),
    loop: () => this.loop()
  }

  totalImages = 0;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);


    this.artists = [...GetBy.class('__artist')];

    if (!isMobile) {
      this.canvas = GetBy.id('CanvasFront');
      this.ctx = InterfaceCanvas.ctx;
      const images = [...GetBy.class('__image')];
      images.map(item => {
        this.totalImages = this.images.push(new Project_Image(item, this.ctx));
      })
    }

    this.setupAnimation();

    this.onShow = () => {
      this.timeline.restart();

      if (isMobile) return;
      gsap.ticker.add(this.calls.loop);
    };

    this.onMove = () => {
      BGVideo.blur = (this.progressTop - 1) * 50;
      BGVideo.scale = Math.max(1, this.progressTop + (this.progressTop - 1) * 2);
    };

    this.onHide = () => {
      if (isMobile) return;
      gsap.ticker.remove(this.calls.loop);
    };
  }

  setupAnimation() {
    this.timeline = gsap.timeline({ paused: true });
    let j = 0;
    let delay = 0;

    Functions.arrayRandom(this.artists).map(dom => {
      this.timeline.from(dom.parentNode, {
        alpha: 0,
        duration: .2,
        force3D: true
      }, delay);

      delay += (Maths.maxminRandom(8, 2) / 100);

      dom.addEventListener(Basics.mouseOver, this.calls.hover);
      dom.addEventListener(Basics.mouseOut, this.calls.out);
    });
  }

  hover(target) {
    clearTimeout(this.idTimerOut);

    BGVideo.play(target.getAttribute('data-hover-video'));

    /*if (isMobile) return;
    
    this.images.map(image => {
      if (image.id !== target.dataset.artistId) image.enabled = false;
      else image.enabled = true;
    });*/
  }

  out() {
    this.idTimerOut = setTimeout(()=>{
      BGVideo.hide();
    },150);
  }

  loop() {
    for(let i=0; i<this.totalImages;i++) {
      this.images[i].draw();
    }
  }

  resize() {
    super.resize();

    if (!this.canvas) return;

    this.sizes.width = this.canvas.offsetWidth * Sizes.RATIO;
    this.sizes.height = this.canvas.offsetHeight * Sizes.RATIO;

    this.images.map(img => img.resize());
  }

  dispose() {
    clearTimeout(this.idTimerOut);
    if(this.ctx) this.ctx.clearRect(0, 0, this.sizes.width, this.sizes.height);
    this.images.map(img => img.dispose());
    this.artists.map(artist => {
      artist.removeEventListener(Basics.mouseOver, this.calls.hover);
      artist.removeEventListener(Basics.mouseOut, this.calls.out);
    });
    super.dispose();
  }
}

Scroll._registerClass('talents', ScrollItem__Talents);

class Project_Image {
  tick = 0;
  dom;
  id;
  image = new Image();
  _enabled = false;

  width;
  height;
  ratio = 1;

  pos = {
    x: 0,
    y: 0,
  }

  float = {
    x: 0,
    y: 0,
  }

  settings = {
    dirX: 1,
    dirY: 1,
    factorX: 1,
    factorY: 1,
    amplitudeX: 0,
    amplitudeY: 0,
  }

  _ctx;

  set enabled(enabled) {
    this.tick = 0;
    this._enabled = enabled;

    if(enabled) {
      this.pos.offsetX = Maths.maxminRandom(this.width*.25, -this.width*.25);
      this.pos.offsetY = Maths.maxminRandom(this.width*.25, -this.width*.25);
    }
  }

  get enabled() {
    return this._enabled;
  }

  constructor(__dom, __ctx) {
    this.dom = __dom;
    this.id = __dom.dataset.artistId;
    this.image.src = __dom.dataset.src;

    this._ctx = __ctx;

    this.image.onload = () => {
      this.ratio = this.image.width / this.image.height;
    };

    this.resize();
  }

  draw() {
    if (!this.enabled) return;

    this.tick++;

    //if (this.tick % 2 !== 0) return;

    this.float.x = 0;Math.sin(this.tick * this.settings.factorX) * this.settings.amplitudeX * this.settings.dirX;
    this.float.y = 0;Math.cos(this.tick * this.settings.factorY) * this.settings.amplitudeY * this.settings.dirY;

    const domRatio = this.width / this.height;

    let drawWidth, drawHeight;
    let offsetX = 0, offsetY = 0;

    if (domRatio > this.ratio) {
      drawWidth = this.width;
      drawHeight = drawWidth / this.ratio;
      offsetY = (this.height - drawHeight) / 2;
    } else {
      drawHeight = this.height;
      drawWidth = drawHeight * this.ratio;
      offsetX = (this.width - drawWidth) / 2;
    }

    this._ctx.globalAlpha = .92;
    this._ctx.drawImage(this.image, this.pos.x + this.float.x + offsetX + this.pos.offsetX, this.pos.y + this.float.y + offsetY + this.pos.offsetY, drawWidth, drawHeight);
    this._ctx.globalAlpha = 1;

    //this._ctx.drawImage(this.image, 0,0,500,500);
  }

  resize() {
    const rect = this.dom.getBoundingClientRect();

    this.settings.dirX = Math.random() < .5 ? 1 : -1;
    this.settings.dirY = Math.random() <= .5 ? 1 : -1;
    this.settings.amplitudeX = Maths.maxminRandom(10, 6);
    this.settings.amplitudeY = Maths.maxminRandom(28, 18);
    this.settings.factorX = Maths.maxminRandom(.016, .010);
    this.settings.factorY = Maths.maxminRandom(.058, .022);

    this.width = rect.width * Sizes.RATIO;
    this.height = rect.height * Sizes.RATIO;
    this.pos.x = rect.x * Sizes.RATIO;
    this.pos.y = rect.y * Sizes.RATIO;
    this.pos.offsetX = Maths.maxminRandom(this.width*.25, -this.width*.25);
    this.pos.offsetY = Maths.maxminRandom(this.width*.25, -this.width*.25);
  }

  dispose() {
    this.enabled = false;
    this._ctx = null;
  }
}
