import { ShaderMaterial, DoubleSide } from "three";
import CanvasColorTexture from "../textures/CanvasColorTexture";

export const DISTORTEDPIXEL_VERTEX = `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const DISTORTEDPIXEL_FRAGMENT = `
    uniform float opacity;
    uniform vec2 resolution;
    uniform sampler2D dataTexture;
    uniform sampler2D texture1;
    uniform float chromaticIntensity;

    varying vec2 vUv;

    float PI = 3.141592653589793238;
    float scaleCenter = 0.5;

    void main() {
        vec2 newUV = (vUv - vec2(scaleCenter)) * resolution.xy + vec2(scaleCenter);

        vec4 offset = texture2D(dataTexture, vUv);

        // Desplazamiento de color
        vec2 offsetR = offset.rg * chromaticIntensity * 0.5;
        vec2 offsetG = offset.rg * chromaticIntensity * 0.5;
        vec2 offsetB = offset.rg * chromaticIntensity * 0.0;

        // Muestreo de texturas con aberración cromática
        vec4 colorR = texture2D(texture1, newUV - offsetR);
        vec4 colorG = texture2D(texture1, newUV - offsetG);
        vec4 colorB = texture2D(texture1, newUV - offsetB);

        // Combinación de colores con diferentes desplazamientos
        vec4 finalColor = vec4(colorR.r, colorG.g, colorB.b, 1.0);

        gl_FragColor = finalColor;
    }
`;

export const DISTORTION_SETTINGS = {
    cols: 200,
    radius: 1,
    strength: .015,
    relaxation: .15,
    opacity: 1,
    renderTarget: true
}

export const DISTORTEPIXEL_UNIFORMS = {
    texture1: { type: 't', value: new CanvasColorTexture().texture },
    dataTexture: { type: 't', value: new CanvasColorTexture().texture },
    opacity: { type: 'f', value: DISTORTION_SETTINGS.opacity },
    chromaticIntensity: { type: 'f', value: 0.1 },
    resolution: {
        type: 'v2',
        value: { x: 1, y: 1 }
    }
};

export default class DistortedPixelMaterial extends ShaderMaterial {
    constructor() {
        super({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: DISTORTEPIXEL_UNIFORMS,
            fragmentShader: DISTORTEDPIXEL_FRAGMENT,
            vertexShader: DISTORTEDPIXEL_VERTEX,
            transparent: true,
            side: DoubleSide
        });
    }
}
