import { gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';


import { ScrollItem__BillboardProject } from './scroll/ScrollItem__BillboardProject';
import { ScrollItem__Footer } from './scroll/ScrollItem__Footer';
import { ScrollItem__Talents } from './scroll/ScrollItem__Talents';
import { ScrollItem__About } from './scroll/ScrollItem__About';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, setNetworkSpeed, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Legal from './pages/Legal';
import Contact from './pages/Contact';
import About from './pages/About';
import Talents from './pages/Talents';
import Archive from './pages/Archive';
import Project from './pages/Project';
import Home from './pages/Home';
import Artist from './pages/Artist';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import Preloader from "./layout/Preloader";
import Sidemenu from "./layout/Sidemenu";
import Language from "./_app/cuchillo/utils/Language";
import CMP from "./windows/Cmp";
import BGVideo from "./layout/BGVideo";
import LogoGlitch from "./layout/LogoGlitch";
import LogoHeaderGlitch from "./layout/LogoHeaderGlitch";
import Cursor from "./_app/cuchillo/cursor/Cursor";
import { PATHS } from "./dataholder/Logos";

export default class Main {
  static stats;
  static favicons = [4, 2, 6, 3, 2, 7, 4, 6, 2, 1, 5, 7, 4, 1, 5, 4, 6, 5, 7, 3, 2, 6, 4, 1, 3, 7, 2, 5, 6, 4, 1, 3, 6, 4, 3, 1, 5, 3, 7, 5, 4, 2, 6, 3, 1, 7, 5, 2, 7, 5, 2, 6, 7, 1, 6, 3, 5, 1, 7, 3, 5, 7, 1, 3, 2, 5, 6, 1, 4, 6, 2, 4, 5, 2, 7, 6, 5, 4, 7, 1, 4, 7, 3, 2, 4, 1, 7, 6, 5, 3, 7, 4, 6, 3, 7, 2, 1, 5, 6];
  static current_favicon = 0;
  static domFavicon = GetBy.selector("[data-favicon]")[0];
  static paths;

  static init() {
    setNetworkSpeed(LOADING_TIMER);

    gsap.ticker.fps(60);
    
    Basics.id = "Smile_v001"; // ID para cookies   
    Language.init();
    Header.init();
    BGVideo.init();
    Sidemenu.init();
    LogoGlitch.init(PATHS);
    LogoHeaderGlitch.init(PATHS);
    LogoHeaderGlitch.showSmile();
    
    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerWindow.init(); // Control ventanas

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor
    CMP.init(); // Checkea y saca el aviso de cookies
    Cursor.init(document.body,{isMoveCancel:true});

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    Main.doCuchilloInfo();
    Main.setWorker();

    Preloader.init();
    LoaderController.init();
  }

  static setup() {
    Main.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      Cursor.hide();
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => { Scroll.setEnabled(true); });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      ControllerWindow.hideAll();
    });

    
    if(Interaction._hasUserInteraction) {
      //Main.initLoop();
      setTimeout(()=> {Main.loadPaths();}, 1000);
    } else {
      EventDispatcher.addEventListener(Interaction.FIRST_MOVE, () => {
        Main.loadPaths();
        //Main.initLoop();
      }, "main");
    }
    Main.initLoop();
  }

  static initLoop() {
    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }
  }

  static loadPaths() {
    let script = document.createElement('script');
    script.src = document.getElementById('__pathsLogos').getAttribute("href");
    script.addEventListener('load', () => {
      LogoGlitch.setupPaths(LOGOS_PATHS);
      LogoHeaderGlitch.setupPaths(LOGOS_PATHS);
    });
    document.body.appendChild(script);
  }

  static resize() {
    
    Header.resize();
    Sidemenu.resize();
    InterfaceCanvas.resize();
    BG.resize();
    ControllerPage.resize();
    //Guides.resize();
    LogoGlitch.resize();
    LogoHeaderGlitch.resize();
    Cursor.resize();
    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    InterfaceCanvas.loop();

    ControllerPage.loop();
    BG.loop();
    Header.loop();
    Sidemenu.loop();
    LogoGlitch.loop();
    LogoHeaderGlitch.loop();
    Cursor.loop();

    if (Scroll.isScrolling) Scroll.loop();

    //Main.changeFavicon();
  }

  static changeFavicon() {
    Main.domFavicon.setAttribute("href", `/assets/favicons/${Main.favicons[Main.current_favicon]}/favicon.ico`);
    Main.current_favicon = Main.current_favicon < 99 ? Main.current_favicon + 1 : 0;
  }

  static loopDebug() {
    Statics.begin();
    Main.loop();
    //Guides.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://somoscuchillo.com');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('Font: Fraktion Mono by Pangram Pangram');
    console.log('⟶ https://pangrampangram.com/products/fraktion');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}
