const VirtualScroll = require('../../vendor/VirtualScroll')

import { GetBy } from '../core/Element';
import { Maths } from '../utils/Maths';
import InfiniteScroll__Item from './InfiniteScroll__Item';
import { Metrics } from '../core/Metrics';
import { CSS } from '../utils/CSS';
import { MrInteraction } from '../core/Interaction';

export default class InfiniteScroll {
    static AXIS_X = 'X';
    static AXIS_Y = 'Y';

    scroller;
    direction = 1;

    position = 0;
    target = 0;
    speed = 0;
    total_items = 0;
    tick = 0;

    id;
    width;
    height;
    options;

    _container;
    _items = [];

    _enabled = false;
    _isShow = false;

    _axis = 'y';
    _measure = 'height';
    _domAxis = 'top';
    _offsetAxis = 'offsetTop';
    _offsetSize = 'offsetHeight';

    _call;

    _threshold = {
        min: 0,
        max: 0
    }

    //
    // GETTER & SETTER
    //
    get enabled() { return this._enabled; };
    set enabled(__isEnabled) {
        if (this._enabled !== __isEnabled) {
            this._enabled = __isEnabled;

            if(this.scroller) {
                if (__isEnabled) {
                    this.scroller.on(this._call);
                } else {
                    this.scroller.off(this._call);
                }
            }
        }
    };

    constructor(__axis, opts = {}) {
        this._container = opts.container || document.body;
        this._appendContainer = opts.appendContainer || this._container;
        this.width = this._container.offsetWidth;
        this.height = this._container.offsetHeight;

        this.options = {
            axis: __axis || 'Y',
            smooth: opts.smooth || false,
            easing: opts.easing || 0.08,
            maxSpeed: opts.maxSpeed || 400,
            multiplicator: opts.multiplicator || 1,
            itemClass: opts.itemClass || InfiniteScroll__Item,
            offset: opts.offset || 100,
            inverted: opts.inverted || false,
            hasVirtuaScroll: opts.hasVirtuaScroll === false? false : true,
            hasInteraction: opts.hasInteraction,
            gap: opts.gap || 0, // Agregamos el gap como una opción
            minSpeed: opts.minSpeed || 0
        };

        if(this.options.hasVirtuaScroll) {
            this.scroller = new VirtualScroll({
                mouseMultiplier: navigator.platform.indexOf('Win') > -1 ? 1 : 0.5,
                firefoxMultiplier: 50,
                touchMultiplier: 3.5,
                passive: true
            });

            this._call = (e) => { this.check(e) };
        }

        if (this.options.hasInteraction) {
            this._interaction = new MrInteraction(this._container, {
              drag: true,
              axis: 'x',
              dragCheckTime: .05,
              onMove: (n) => {
                if (this.options.onMove) options.onMove();
                this.move(n)
              },
              onDragStart: () => {
                if (this.options.onDragStart) options.onDragStart();
              },
              onDragEnd: () => {
                if (this.options.onDragEnd) options.onDragEnd();
              }
            });
          }

        this._container.classList.add('__vscroll-inifinity');

        switch (this.options.axis) {
            case InfiniteScroll.AXIS_Y:
                this._container.classList.add('__scroll-infinity-axis-y');
                this._axis = 'y';
                this._measure = 'height';
                this._domAxis = 'top';
                this._offsetAxis = 'offsetTop';
                this._offsetSize = 'offsetHeight';
                break;

            case InfiniteScroll.AXIS_X:
                this._container.classList.add('__scroll-infinity-axis-x');
                this._axis = 'x';
                this._measure = 'width';
                this._domAxis = 'left';
                this._offsetAxis = 'offsetLeft';
                this._offsetSize = 'offsetWidth';
                break;
        }

        this.setupResize(opts.domResize || this._container);
        this.addAll(opts.selector);
    }

    move(n) {
        this.check({deltaY:n});
    }

    addAll(__selector = '[scroll-infinity-item]') {
        const items = GetBy.selector(__selector, this._container);
        for (let i = 0; i < items.length; i++) {
            const item = new this.options.itemClass(items[i], i, this._axis == 'y' ? InfiniteScroll.AXIS_Y : InfiniteScroll.AXIS_X);
            this.total_items = this._items.push(item);
        }

        this._threshold.max = this.total_items;
    }

    setupResize(__dom) {
        this.resizeObserver = new ResizeObserver(entries => {
            this.resize();
        });

        this.resizeObserver.observe(__dom);
    }

    //
    // PUBLIC
    //
    start() {
        this.enabled = true;
    }

    show() {
        if (!this._isShow) {
            this.start();
            this.loop(true);
            this._isShow = true;
        }
    }

    hide() {
        this.enabled = false;
        this._container.classList.remove('__vscroll-inifinity');
        this._container.classList.remove('__scroll-infinity-axis-y');
        this._container.classList.remove('__scroll-infinity-axis-x');
    }

    check(e, __direct) {
        if(__direct) e = {deltaY:__direct}
        const d = Maths.precission(e.deltaY * this.options.multiplicator, 2);

        if (this.options.inverted) {
            this.target -= d;
            this.direction = e.deltaY >= 0 ? 1 : -1
        } else {
            this.target += d;
            this.direction = e.deltaY < 0 ? 1 : -1;
        }

        this.checkHook(e);
        this._container.parentNode.style.setProperty('--direction', this.direction==1?0:1);
    }

    checkHook() {}

    loop(__force = false) {
        this.tick = this.tick + 1;
        this.target = this.target - this.options.minSpeed;
        if (Maths.precission(this.target) !== Maths.precission(this.position) || __force) {
            this.checkItemsReorder();
            this.calcSpeed();
            this.updateItemsPosition();
        }
    }

    checkItemsReorder(__force = false) {
        const diff = this.target - this.position;
        if (diff < 0 || __force) {
            for (let i = 0; i < this.total_items; i++) {
                const item = this._items[i];
                const itemBottom = item[this._axis] + item[this._domAxis] + item[this._measure];

                if (itemBottom < -this.options.offset) {
                    item.index = this._threshold.max;
                    item.place(this._baseSize, this._gap);
                    item.resizeLimits();

                    this._threshold.min++;
                    this._threshold.max++;
                }
            }
        } else {
            for (let i = this.total_items - 1; i >= 0; i--) {
                const item = this._items[i];
                const itemTop = item[this._axis] + item[this._domAxis];

                if (itemTop > this[this._measure] + this.options.offset) {
                    this._threshold.min--;
                    this._threshold.max--;

                    item.index = this._threshold.min;
                    item.place(this._baseSize, this._gap);
                    item.resizeLimits();

                }
            }
        }
    }

    calcSpeed() {
        this.speed = (this.target - this.position) * this.options.easing;

        if (this.speed === 0) this.position = this.target;
        else this.position += this.speed;       
    }

    updateItemsPosition() {
        for (let i = 0; i < this.total_items; i++) {
            this._items[i].tick = this.tick;
            this._items[i][this._axis] = this.position;
            this._items[i].speed = this.speed;
            const dir = this.options.inverted ? this.direction * -1 : this.direction;
            this._items[i].direction = dir;
        }
    }

    placeItems() {
        for (let i = 0; i < this.total_items; i++) {
            this._items[i].place(this._baseSize, this._gap);
        }
    }

    resize() {
        this.width = this._container.offsetWidth;
        this.height = this._container.offsetHeight;

        this.options.offset = this._items[0].width * this._items.length * .35;

        if (this._isShow) this.loop(true);

        for (let i = 0; i < this.total_items; i++) {
            this._items[i].resize(this._appendContainer[this._offsetSize]);
        }

        if (this.total_items > 0) this._baseSize = this._items[0][this._measure];
        else this._baseSize = 0;

        this._gap = Metrics.parseSize(this.options.gap + 'fpx');

        this.placeItems();
        this.checkItemsReorder(true);
        this.updateItemsPosition();

        for (let i = 0; i < this.total_items; i++) {
            const item = this._items[i];
            item.place(this._baseSize, this._gap);
            item.resizeLimits();
        }
    }

    dispose() {
        this.enabled = false;

        for (let i = 0; i < this.total_items; i++) this._items[i].dispose();

        this.total_items = 0;
        this._items = [];

        if(this.scroller) {
            this.scroller.destroy();
        }

        if(this._interaction) {
            this._interaction.dispose();
        }

        this.resizeObserver.disconnect();
    }
}