import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';

export default class Talents extends Default {

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();
    LogoGlitch.goBottom();
  }

  afterHide() {
    super.afterHide();
  }
}

ControllerPage._addPage('page-talents', Talents)
