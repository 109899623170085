import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import LogoGlitch from '../layout/LogoGlitch';
import Default from './Default';

export default class Contact extends Default {
   
//==================================================================================================================
//          CONSTRUCTOR
//==================================================================================================================

  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();
    LogoGlitch.goBottom();
    LogoGlitch.showLettering();
    LogoGlitch.start();
  }

  afterHide() {
    LogoGlitch.stop();
    super.afterHide();
  }
}

ControllerPage._addPage("page-contact", Contact)
