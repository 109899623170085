import _Header from '../_app/cuchillo/layout/Header';
import gsap, { Power2, Power3 } from "gsap";

export default class Header extends _Header {
  static options = {
    show: {
      duration: .3,
      delay: 0,
      ease: Power3.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static hideAlpha() {
    gsap.to(this.container,
      {
        alpha: 0,
        duration: .3,
        delay: 0,
        ease: Power2.easeOut
      });
  }

  static showAlpha() {
    gsap.to(this.container,
      {
        alpha: 1,
        duration: .3,
        delay: 0,
        ease: Power2.easeOut
      });
  }
}


