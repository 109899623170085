import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Sizes } from '../_app/cuchillo/core/Sizes';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class LogoGlitch {
  static color = "#E5FF70";
  static tick = 0;
  static images = [];
  static current = 0;
  static hasEffect = false;
  static isEnabled = false;
  static options = { alpha: .95 };
  static canvas = {
    front: GetBy.id("CanvasFront"),
    back: GetBy.id("CanvasBack"),
  };
  static ctx = {
    front: LogoGlitch.canvas.front.getContext('2d'),
    back: LogoGlitch.canvas.back.getContext('2d'),
  };
  static width;
  static height;
  static image;
  static idTimer;
  static paths;
  static offsetY = 0;
  static modY = 1;

  static init(__paths) {
    this.setupPaths(__paths);
  }

  static setupPaths(__paths) {
    this.paths = __paths;

    for (let i = 0; i < this.paths.length; i++) {
      if(i>1 && i + 1 === this.paths.length) {
        this.images.push(new LogoGlitch__Image(this.paths[i], LogoGlitch.ctx.front, 1440, 900));
      } else {
        this.images.push(new LogoGlitch__Image(this.paths[i], LogoGlitch.ctx.front))
      }
    }
  }

  static showLettering() {
    this.current =  Math.max(0, this.images.length - 1);
  }

  static showSmile() {
    this.current =  Math.max(0, Maths.maxminRandom(this.images.length - 2, 0));
  }

  static start() {
    clearTimeout(this.idTimer);
    this.idTimer = setTimeout(() => this.enableEffect(), Maths.maxminRandom(8000, 2000) * 10);
    this.images[this.current].offset = Metrics.HEIGHT * .2;
    this.isEnabled = true;
  }

  static startBasic() {
    this.isEnabled = true;
  }

  static stopBasic() {
    this.clearTimer();
    this.clearCanvas();
  }

  static stop() {
    this.clearTimer();
    this.images[this.current].offset = Metrics.HEIGHT * .2;
    this.idTimer = setTimeout(() => {
      this.isEnabled = false;
      this.offsetY = 0;
      this.modY = 1;
      this.clearCanvas();
    }, 500);
  }

  static gotoMid() {
    this.updateCanvasClass("--canvas-mid", .95);
  }

  static goBottom() {
    this.updateCanvasClass("--canvas-bottom", .99);
  }

  static goTop() {
    this.updateCanvasClass("--canvas-top", .99);
  }

  static enableEffect() {
    this.hasEffect = true;
    setTimeout(() => this.hasEffect = false, Maths.maxminRandom(180, 120) * 10);
    this.idTimer = setTimeout(() => this.enableEffect(), Maths.maxminRandom(8000, 2000) * 10);
  }

  static loop() {
    if (!this.isEnabled) return;

    this.tick++;
    if (this.tick % 4 === 0 || this.hasEffect) this.clearCanvas();

    const y = ((this.offsetY + Scroll.y) * this.modY) * Sizes.RATIO;

    this.ctx.front.translate(0, y);
    this.ctx.front.globalAlpha = this.options.alpha;
    this.images[this.current].draw(this.hasEffect, this.color);
    this.ctx.front.translate(0, -y);

    this.ctx.back.drawImage(this.canvas.front, 0, 0);
    this.ctx.front.globalAlpha = 1;
  }

  static resize() {
    this.width = this.canvas.front.offsetWidth * Sizes.RATIO;
    this.height = this.canvas.back.offsetHeight * Sizes.RATIO;
    ["front", "back"].forEach(canvasType => {
      this.canvas[canvasType].setAttribute('width', this.width);
      this.canvas[canvasType].setAttribute('height', this.height);
    });
    this.images.forEach(image => image.resize());
  }

  static clearTimer() {
    clearTimeout(this.idTimer);
    this.isEnabled = false;
  }

  static clearCanvas() {
    this.ctx.front.clearRect(0, 0, this.width, this.height);
    this.ctx.back.clearRect(0, 0, this.width, this.height);
  }

  static updateCanvasClass(newClass, alpha) {
    document.body.classList.remove("--canvas-top", "--canvas-mid", "--canvas-bottom");
    document.body.classList.add(newClass);
    this.options.alpha = alpha;
  }
}

class LogoGlitch__Image {
  image = new Image();
  enabled = false;
  alpha = .99;
  path2D;
  _scale;
  _width;
  _height;
  _heightSVG;
  _x = 1;
  _y = 1;
  tick = 0;
  tickStop = 0;
  offset = 4;
  maxOffset;
  minOffset;
  tickLimit = 30;

  constructor(__path, __ctx, __w = 80, __h = 70) {
    this._ctx = __ctx;
    this._ratio = __w / __h;
    this._heightSVG = __h;
    this.path2D = new Path2D(__path);
    this.resize();
  }

  draw(__hasEffect = false, color) {
    this.tick++;
    let modX = 0, modY = 0, modXp = 0, modYp = 0, rotationAngle = 0;

    if (this.tick % this.tickLimit === 0) {
      this.offset = Maths.maxminRandom(this.maxOffset * 2, this.minOffset * 2);
      this.tickLimit = Maths.maxminRandom(200, 40);
    }

    this.offset = Math.max(this.minOffset, this.offset * .9);

    if (this.tick % 4 === 0 && __hasEffect) {
      rotationAngle = Maths.maxminRandom(20, -20) / 1000;
    } else if (this.tick % 4 === 0) {
      modX = Maths.maxminRandom(this.offset, -this.offset) * Sizes.RATIO * 2;
      modY = Maths.maxminRandom(this.offset, -this.offset) * Sizes.RATIO * 2;
      modXp = Maths.maxminRandom(this.offset, -this.offset) / this._heightSVG;
      modYp = Maths.maxminRandom(this.offset, -this.offset) / this._heightSVG;
    }

    this._ctx.save();
    this._ctx.translate(this._x + modX * .5, this._y + modY * .5);
    this._ctx.scale(this._scale + modXp, this._scale + modYp);
    this._ctx.fillStyle = color;
    this._ctx.fill(this.path2D);
    this._ctx.restore();
  }

  resize() {
    this.updateDimensions();
    this._x = (Metrics.CENTER_X * Sizes.RATIO) - this._width * .5;
    this._y = (Metrics.CENTER_Y * Sizes.RATIO) - this._height * .5;
  }

  updateDimensions() {
    this._scale = (Metrics.HEIGHT * Sizes.RATIO * 1.2) / this._heightSVG;
    this._height = this._heightSVG * this._scale;
    this._width = this._height * this._ratio;
    this.maxOffset = (this._heightSVG / 200) * this._scale;
    this.minOffset = (this._heightSVG / 1000) * this._scale;
  }
}
