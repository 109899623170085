import { CanvasTexture } from 'three';

export default class CanvasColorTexture {
    texture;
    canvas;
    ctx;

    constructor(color = '#FFFFFF') {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');

        this.canvas.width = 2;
        this.canvas.height = 2;

        if (color !== 'transparent') {
            this.ctx.fillStyle = color;
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        }

        this.texture = new CanvasTexture(this.canvas);
    }
}
