import { gsap, Power0, Power2 } from 'gsap';

import { isTouch } from '../core/Basics';
import VScroll from '../scroll/VScroll';
import { Accessibility } from '../core/Accessibility';
import { GetBy } from '../core/Element';
import EventDispatcher from '../core/EventDispatcher';
import Keyboard, { KEYS }  from '../core/Keyboard';

export default class Sidemenu {
  static container = GetBy.id('Sidemenu');

  static ON_SHOW = "onshow";
  static ON_SHOW_END = "onshowend";
  static ON_HIDE = "onhide";
  static ON_HIDE_END = "onhideend";

  static STATE_OPEN = "OPEN";
  static STATE_CLOSE = "CLOSE";

  static options = {
    container: this.container,
    isMain: false
  };

  _texts;
  _state;

  static get isOpen() { return this._state === Sidemenu.STATE_OPEN; }
  static get state() { return this._state };
  static set state(__state) {
    if(this._state === __state) return;

    this._state = __state;

    if (this.isOpen) {
      Keyboard.add(KEYS.ESC, 'Sidemenu_ESC', () => { this.hide(); });
      Accessibility.trap(this.container);
      EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW);
    } else {
      Keyboard.remove(KEYS.ESC, 'Sidemenu_ESC');
      Accessibility.removeTrap();
      EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE);
    }
  }

  static init () {
    Sidemenu.directHide();
    
    this._texts = Array.from(GetBy.class('__text', this.container));

    /* BUG RESIZE */
    if (!isTouch) {
      this.engine = new VScroll(this.options);
      this.engine.addAll('[scroll-sidemenu-item]');
      this.engine.resize();
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  static toggleState() {
    if(!this.isOpen) this.show();
    else this.hide();
  }

  static show(__d = 0) {
    this.container.style.visibility = 'visible';
    this.container.setAttribute('aria-expanded', 'true');
    this.state = Sidemenu.STATE_OPEN;
    this.show__effect();
  }

  // SHOW
  static show__effect(__d = 0) {
    gsap.set(this.container, { alpha: 0 });
    gsap.set(this._texts, { y: '100%' });

    gsap.to(this.container, {
      alpha: 1,
      duration: 0.3,
      delay: __d,
      ease: Power0.easeOut
    });

    this._texts.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: 1,
        delay: __d + 0.05 * i,
        ease: Power2.easeInOut
      });
    });

    gsap.delayedCall(0.05 * this._texts.length, () => { this.afterShow(); });
  }

  static afterShow() {
    EventDispatcher.dispatchEvent(Sidemenu.ON_SHOW_END);
    this.enableScroll();
  }

  // HIDE
  static hide(__d = 0) {
    this.disableScroll();
    this.state = Sidemenu.STATE_CLOSE;
    this.hide__effect();
  }

  static directHide() {
    this.disableScroll();
    gsap.set(this.container, { alpha: 0 });
    this._state = Sidemenu.STATE_CLOSE;
    this.afterHide();
  }

  static hide__effect(__d = 0) {
    this._texts.map((item, i) => {
      gsap.to(item, {
        y: '100%',
        duration: 0.6,
        delay: __d,
        ease: Power2.easeInOut
      });
    });

    gsap.to(this.container, {
      alpha: 0,
      delay: 0.3,
      duration: 0.3,
      delay: __d,
      ease: Power2.easeOut,
      onComplete: () => {
        this.afterHide();
      }
    });
  }

  static afterHide() {
    this.container.style.visibility = 'hidden';
    this.container.setAttribute('aria-expanded', 'false');
    EventDispatcher.dispatchEvent(Sidemenu.ON_HIDE_END);
  }

  static enableScroll() {
    if (this.engine && !this.engine.enabled) this.engine.enabled = true;
  }
  
  static disableScroll() {
    if (this.engine && this.engine.enabled) this.engine.enabled = false;
  }

  static loop() {
    if (this.engine && this.engine.enabled) this.engine.loop();
  }

  static resize() {
    if (this.engine && this.engine.enabled) this.engine.resize();
  }
}
