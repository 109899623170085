import { Videos } from '../_app/cuchillo/components/Videos';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Cmp from '../windows/Cmp';
import { GetBy } from '../_app/cuchillo/core/Element';
import ShfflController from '../components/ShfflController';
import ShffleLinks from '../components/ShffleLinks';
import BGVideo from '../layout/BGVideo';

export default class Default extends Page {

  _shfflController;

  constructor() {
    super();

    Videos.init();
    ShffleLinks.init();
    this._shfflController = new ShfflController(this.container);
    BGVideo.start(()=>{this.videoLoaded()});

    /*[...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.addEventListener(Basics.clickEvent, Cmp._calls.click);
    });*/
  }

  videoLoaded() {}

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Scroll.setScrollbar(new Scrollbar());
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.directShow();
      this._shfflController.show();
      Preloader.hide(() => { this.afterShow() });
    } else {
      this._shfflController.show();
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    this._shfflController.hide();
    BGVideo.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
    });

    this.dispose();

    super.afterHide();
  }

  dispose() {
    Videos.dispose();
    if(this._shfflController) this._shfflController.dispose();
    this._shfflController = null;
  }

  //RESIZE
  resize() {
    super.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
