import gsap, { Power1, Power2 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from "../_app/cuchillo/core/Basics";

export default class Preloader {
    _container;
    _progress;

    static init () {
        this._container = GetBy.id('Preloader');
        /*this._progress = GetBy.class('__progress', this._container);

        if(isDebug) this._container.style.display = "none"; */
    }

    static update (__progress) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: __progress,
            ease: Power1.easeOut,
            duration: .1
        });
    }

    static hide (__call) {
       // if(isDebug) {
        gsap.to(this._container, {
            alpha: 0,
            duration: .2,
            opacity: 0,
            delay: 0,
            ease: Power2.easeIn,
            onComplete: () => {
                this._container.style.display = "none";
            }
        });
            __call();
            return;
       // }

        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: 1,
            ease: Power1.easeOut,
            duration: .2
        });
        gsap.to(this._container, {
            duration: .5,
            opacity: 0,
            delay: .5,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });
    }
}
